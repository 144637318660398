import React from "react";
import "../styles/section6.scss";
import { ArrowRight } from "phosphor-react";

export default function Section6(props) {
  return (
    <div className="section s6">
      <div className="content">
        <div className="title">
          <div className="bar" />
          <p>{props.lang.landingPage.section6.title}</p>
        </div>
        <div className="contactMethods">
          {props.lang.landingPage.section6.buttons.map((button, id) => (
            <>
              {button.type === "mail" && (
                <a
                  href={"mailto: " + button.mail}
                  className={"button " + "button" + id}
                  key={id}
                  target="_blank"
                >
                  <p>{button.name}</p>
                  <div className="arrow">
                    <ArrowRight size={15} color="#A0A4BD" />
                  </div>
                </a>
              )}

              {button.type === "link" && (
                <a
                  href={button.link}
                  className={"button " + "button" + id}
                  key={id}
                  target="_blank"
                >
                  <p>{button.name}</p>
                  <div className="arrow">
                    <ArrowRight size={15} color="#A0A4BD" />
                  </div>
                </a>
              )}

              {button.type === "phone" && (
                <a
                  href={"tel: " + button.phone}
                  className={"button " + "button" + id}
                  key={id}
                  target="_blank"
                >
                  <p>{button.name}</p>
                  <div className="arrow">
                    <ArrowRight size={15} color="#A0A4BD" />
                  </div>
                </a>
              )}
            </>
          ))}
        </div>
      </div>
      <div className="line l1" />
      <div className="line l2" />
    </div>
  );
}
