import React, { useState } from "react";
import "../styles/fakeCarousel.scss";
import { motion } from "framer-motion";
import ManifestoActivityCard from "./ManifestoActivityCard";



export default function ManifestoFakeCarousel(props) {
  const [x, setX] = useState(0);
  function handleNext(index) {
    if (index % props.cards.length === 1) {
      // setX((((x + 1) % 3) + 3) % 3);
      setX((((x + 1) % 7) + 7) % 7);
      // ((n % m) + m) % m;
    }
  }
  return (
    <div className="fakeCarousel fakeCarouselManifesto">
      {props.cards.map((card, index) => (
        <ManifestoActivityCard
          card={card}
          // index={(((index - x) % 3) + 3) % 3}
          index={(((index - x) % 7) + 7) % 7}
          total={props.cards.length}
          handleNext={handleNext.bind(this)}
        />
      ))}
    </div>
  );
}
