import React from "react";
import "../styles/section3.scss";
export default function Section3(props) {
  return (
    <div className="section s3">
      <div className="title">
        <div className="bar" />
        <p>{props.lang.landingPage.section3.title}</p>
      </div>
      <div className="categories">
        {props &&
          props.lang.landingPage.section3.categories.map((category, index) => (
            <div className={`category category${index}`} key={index}>
              <p className="title">{category.title}</p>
              <p className="text">{category.text}</p>
            </div>
          ))}
      </div>
      <div className="magicNumbers">
        {props &&
          props.lang.landingPage.section3.magicNumbers.map((number, index) => (
            <div className={`number number${index}`} key={index}>
              <p className="line0">{number.line0}</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p className="line1">{number.line1}{index == 2 ?<>&#13217;</> : <></> }</p>
              </div>
              <p className="line2">{number.line2}</p>
            </div>
          ))}
      </div>
      <div className="line l1" />
    </div>
  );
}
