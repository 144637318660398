import React from 'react'
import "../styles/knowledgehub.scss";
//import arrow right from phosphor icons    
import { ArrowRight } from "phosphor-react";


const KnowledgeHub = (props) => {
  return (
    <>
    {props.manifesto && (
                <div className='knowledgehub-container'>
        <div className="content">
            <div className="bar"></div>
            <div className='title-group'> 
                <div className="title">{props.lang.landingPage.knowledgehub.title1}</div>
                <div className="subtitle">{props.lang.landingPage.knowledgehub.subtitle1}</div>
            </div>
            
            <div className='title-group'> 
                <div className="title t2">{props.lang.landingPage.knowledgehub.title2}</div>
                <div className="title t2">{props.lang.landingPage.knowledgehub.title22}</div>
                <span className="subtitle post-name small">{props.lang.landingPage.knowledgehub.subtitle2}</span> 
                <span className="subtitle small"> {props.lang.landingPage.knowledgehub.name} </span>
            </div>

            
            
            <div className="text"> {props.lang.landingPage.knowledgehub.content} </div>
                <a href={props.lang.landingPage.knowledgehub.link} target="_blank">
            
                <div className='button-group'>
                        <div className="button"> {props.lang.landingPage.knowledgehub.button} </div>
                        <ArrowRight size={14}/>
                </div>
            </a>
            
        
        </div>

        {/* <div className="image" style={{ backgroundImage: `url(../assets/images/manifesto_hero_1.jpg)` }} ></div> */}
         <div className="image"  ></div> 

         <div className="hor-2-lines">
            <div className="line1"></div>
            <div className="line2"></div>
        </div>
        
    
    </div>
    )}

    </>


   
  )
}

export default KnowledgeHub