import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import ScrollToTop from "./components/ScrollToTop";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import ManifestoPage from "./pages/ManifestoPage";
import Footer from "./components/Footer";
import CastlePage from "./pages/CastlePage";

ReactDOM.render(
  <BrowserRouter>
  <ScrollToTop />
  <Routes>
  
    <Route path="/" element={<App />} />
    <Route path="/manifesto" element={<ManifestoPage />} />  
    <Route path="/placemaking-hub-castelnuovo-rangone" element={<CastlePage />} />  
  </Routes>
  <Footer />
</BrowserRouter>,
  
  document.getElementById("root")
);
