import React from 'react'
import "../styles/manifesto2.scss";

const Castle2 = (props) => {
  return (
    <div className='manifesto2-container'>

        {props.lang.section2.paragraphs && props.lang.section2.paragraphs.map((paragraph, index) => {
            return (
                <div className={`col c${index+1}`} key={index}>
                    <div className="title">{paragraph.title}</div>
                    <div className="text">{paragraph.text}</div>
                </div>
            )
        }
        )}
    </div>
       )  
    }


export default Castle2