import React, { useEffect } from "react";
import "../styles/castleSliderCard.scss";
import { motion } from "framer-motion";
import { ArrowRight } from "phosphor-react";
import img1 from "../assets/images/slider/img1.png";
import img2 from "../assets/images/slider/img2.jpg";
import img3 from "../assets/images/slider/img3.JPG";
import img4 from "../assets/images/slider/img4.JPG";
import img5 from "../assets/images/slider/img5.JPG";
import img6 from "../assets/images/slider/img6.JPG";
import img7 from "../assets/images/slider/img7.jpg";
import img8 from "../assets/images/slider/img8.jpg";




let pictures = {
    img1: img1,
    img2: img2,
    img3: img3,
    img4: img4,
    img5: img5,
    img6: img6,
    img7: img7,
    img8: img8,
  };




export default function CastleSliderCard(props) {
  // useEffect(() => {
  //   console.log(props.index);
  // }, [props]);

  let cardVariants = {
    visible: (i) => ({
      x:
        i === 7
          ? `calc((4 * var(--col) + 4 * 20px) * ${-1})`
          : `calc((4 * var(--col) + 4 * 20px) * ${i})`,
      // x: (((1366 - 139 * 2 - 11 * 20) / 12) * 10 + 9 * 20 + 139 + 20) * (i % 3),
      cursor: i === 3 ? "pointer" : "",
      transition: {
        duration: 0.7,
      },
      // scale: i === 3 ? 0 : 1,
      opacity: i < 4 ? 1 : 0,
      // opacity: i === 0 || i === 1 ? 1 : 0,
      zIndex: i,
    }),
    hidden: { opacity: 0 },
  };





  return (
    <motion.div
    
      custom={props.index}
      className="castleCard"
      variants={cardVariants}
      animate="visible"
      initial="visible"
      onClick={() => {
        props.handleNext(props.index);
      }}
      style={{ backgroundImage: `url(${pictures[props.card.shorthand]})` }}
    >
         {/* <div className="activityCardBg" >
         </div> */}

   
    
    </motion.div>
  );
}
