import React from 'react'
import { useEffect } from 'react';
import "../styles/manifesto1.scss";
import CarouselImages from "./CarouselImages";

const Manifesto1 = (props) => {
  useEffect(() => {
    console.log(props.lang);
  }, [props.lang]);

  return (
    <>
    {/* <CarouselImages cards={props.lang.section1.pictures}/> */}
    <div className='manifesto1-container'>
      <div className="pictures">
        <div className="picture p1"> </div>
        <div className="picture p2"> </div>
        {/* <div className="picture p2" style={{ backgroundImage: `url(https://images.unsplash.com/photo-1666833375493-2fb33e8ee28c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)` }}> 21 </div> */}
      </div>

      <div className="text">
        <p className="row1">
          <span className='black'>{props.lang.section1.bigText.row1.p1}</span> 
          {/* <span className='grey'>{props.lang.section1.bigText.row1.p2}</span>   */}
        </p>
        {/* <p className="row2">  
         <span className='grey'>{props.lang.section1.bigText.row2.p1} </span> 
          <span className='black'>{props.lang.section1.bigText.row2.p2}</span>
        </p> */}

      </div>
    </div>
    
    </>
  )
}

export default Manifesto1