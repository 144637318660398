import React from "react";
import "../styles/section4.scss";
import FakeCarousel from "./FakeCarousel";
import Carousel from "./Carousel";
export default function Section4(props) {
  return (
    <div className="section s4">
      <FakeCarousel cards={props.lang.landingPage.section4.activities} />
      <Carousel cards={props.lang.landingPage.section4.activities} />
    </div>
  );
}
