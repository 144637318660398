import React from "react";
import "../styles/castleVideo.scss";

import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";

import {
  MediaCommunitySkin,
  MediaOutlet,
  MediaPlayer,
  MediaPoster,
} from "@vidstack/react";

import main from "../assets/thumbnails/main.jpg";

const VideoCastle = () => {
  return (
    <div className="video-container">
      {/* <video controls>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <MediaPlayer
        title="Castelnuovo Rangone"
        src="https://gate-rei-bucket.s3.eu-central-1.amazonaws.com/Castelnuovo+Placemaking+Hub_1.mp4"
        poster={main}
        // thumbnails="https://media-files.vidstack.io/sprite-fight/thumbnails.vtt"
        aspectRatio={16 / 9}
      >
        <MediaOutlet>
          <MediaPoster alt="Castelnuovo Rangone" />
        </MediaOutlet>
        <MediaCommunitySkin />
      </MediaPlayer>
    </div>
  );
};

export default VideoCastle;
