import React, { useEffect } from "react";
import "../styles/activityCard.scss";
import { motion } from "framer-motion";
import { ArrowRight } from "phosphor-react";
import had from "../assets/images/had.jpg";
import nam from "../assets/images/nam.jpg";
import pia from "../assets/images/pia.jpg";
import fio from "../assets/images/fio.jpg";
import mon from "../assets/images/mon.jpg";
import gate from "../assets/images/gate.jpg";
import opi from "../assets/images/opi.jpg";
import cas from "../assets/images/cas.jpeg";

let pictures = {
  had: had,
  nam: nam,
  pia: pia,
  fio: fio,
  mon: mon,
  gate: gate,
  opi: opi,
  cas: cas,
};
let cardVariants = {
  visible: (i) => ({
    x:
      // i === 3
      i === 6
        ? // i === 6
        `calc(((((100vw - 139px * 2) - 11 * 20px) / 12 * 10) + 9 * 20px + 139px + 20px)*${-1})`
        : `calc(((((100vw - 139px * 2) - 11 * 20px) / 12 * 10) + 9 * 20px + 139px + 20px)*${i})`,
    // x: (((1366 - 139 * 2 - 11 * 20) / 12) * 10 + 9 * 20 + 139 + 20) * (i % 3),
    cursor: i === 1 ? "pointer" : "",
    transition: {
      duration: 0.7,
    },
    // scale: i === 3 ? 0 : 1,
    opacity: i === 0 || i === 1 ? 1 : 0,
    // opacity: i === 0 || i === 1 ? 1 : 0,
    zIndex: i,
  }),
  hidden: { opacity: 0 },
};

let contentVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.6, duration: 0.3 },
  },
  closed: {
    opacity: 0,
    y: 72,
    transition: { delay: 0.6, duration: 0.7 },
  },
};

let arrowVariants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
};

export default function ActivityCard(props) {
  // useEffect(() => {
  //   console.log(props.index);
  // }, [props]);
  return (
    <motion.div
      custom={props.index}
      className="activityCard"
      variants={cardVariants}
      animate="visible"
      initial="visible"
      onClick={() => {
        props.handleNext(props.index);
      }}
      style={{ backgroundImage: `url(${pictures[props.card.shorthand]})` }}
    >
      <div className="activityCardBg" />
      <motion.div
        variants={contentVariants}
        animate={props.index === 0 ? "open" : "closed"}
        initial={props.index === 0 ? "open" : "closed"}
        className="content"
      >
        <div className="title">
          <p className="title1">{props.card.title1}</p>
          <p className="title2">{props.card.title2}</p>
          <div className="bar" />
        </div>
        <div className="text">
          <p className="text1">{props.card.text1}</p>
          <p className="text2">{props.card.text2}</p>
        </div>
        <a
          href={props.card.link}
          target={props.card.shorthand === "cas" ? "_self" : "_blank"}
          // the castle [cas] card is different from the others because
          // it must open up in the same window, as it's an internal page, not an external one
          className="link"
        >
          <p>{props.card.linkPlaceholder}</p>
          <ArrowRight size={18} />
        </a>
      </motion.div>
    </motion.div>
  );
}
