import React from "react";
import "../styles/interviewGrid.scss";

import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";

import {
  MediaCommunitySkin,
  MediaOutlet,
  MediaPlayer,
  MediaPoster,
} from "@vidstack/react";

import marta from "../assets/thumbnails/marta.jpg";
import mara from "../assets/thumbnails/mara.jpg";
import massimo from "../assets/thumbnails/massimo.jpg";
import fabio from "../assets/thumbnails/fabio.jpg";

let pictures = {
  marta,
  mara,
  massimo,
  fabio,
};

const InterviewGrid = (props) => {
  return (
    <div className="interview-grid-container">
      <div className="blue-line"></div>
      <div className="title"> {props.videos.title} </div>
      <div className="interview-grid">
        {props.videos.videos.map((elem, index) => (
          <div key={index} className="video-section">
            <MediaPlayer
              title={elem.title}
              src={elem.link}
              poster={pictures[elem.shorthand]}
              thumbnails="https://media-files.vidstack.io/sprite-fight/thumbnails.vtt"
              aspectRatio={16 / 9}
            >
              <MediaOutlet>
                <MediaPoster alt="Castelnuovo Rangone Interview" />
              </MediaOutlet>
              <MediaCommunitySkin />
            </MediaPlayer>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InterviewGrid;
