import React from "react";
import CastleCarousel from "./CastleCarousel";
import CastleSlider from "./CastleSlider";
import it from "../lang/it.json";
import "../styles/castleCarouselSection.scss";
const CastleCarouselsSection = () => {
  return (
    <>
      <div className="blue-line"></div>
      <div className="title-group">
        <div className="title"> {it.castlePage.slider.title}</div>
        <div className="subtitle"> {it.castlePage.slider.subtitle}</div>
      </div>

      <CastleSlider cards={it.castlePage.slider.activities} />
      <CastleCarousel cards={it.castlePage.slider.activities} />

    </>
  );
};

export default CastleCarouselsSection;
