import React from "react";
import "../styles/section2.scss";
import logo from "../assets/icons/logo.png";
import s2 from "../assets/images/s2.jpg";
export default function Section2(props) {
  return (
    <div className="section s2">
      {<img className="logo" src={logo} alt="gate-rei" />}
      <div className="title">
        <p className="preTitle">{props.lang.landingPage.section2.preTitle}</p>
        <p className="title1">{props.lang.landingPage.section2.titleP1}</p>
        <p className="title2">{props.lang.landingPage.section2.titleP2}</p>
      </div>
      <p className="text">{props.lang.landingPage.section2.text}</p>
      <img className="bgImg" style={{ backgroundImage: `url(${s2})` }} />
      <img className="bgGradient" />
      <div className="whiteBar wb1" />
      <div className="whiteBar wb2" />
      <div className="line l1" />
      <div className="line l2" />
      <div className="line l3" />
      <div className="line l4" />
    </div>
  );
}
