import React from "react";
import "../styles/section4.scss";
import ManifestoFakeCarousel from "./ManifestoFakeCarousel";
import ManifestoCarousel from "./ManifestoCarousel";
export default function Section4(props) {
  return (
    <div className="section s4">
      <ManifestoFakeCarousel cards={props.lang.manifestoPage.section3.beliefs} />
      <ManifestoCarousel cards={props.lang.manifestoPage.section3.beliefs} />
    </div>
  );
}
