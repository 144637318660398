import React from 'react'
import "../styles/castleHub.scss";
//import arrow right from phosphor icons    
import { ArrowRight } from "phosphor-react";

import pdf from "../assets/pdf/Digital Innovation Hub - Castelnuovo Rangone.pdf"

import pdfC from "../assets/images/pdf-castle.png"

import { Link } from "react-router-dom";


const CastleHub = (props) => {
  return (
    <div className='castleHub-page'>
    <div className="content">
        <div className="blue-line"></div>
        <div className='title-group'> 
            <div className="title">{props.lang.castlePage.knowledgehub.title1}</div>
            <div className="subtitle">{props.lang.castlePage.knowledgehub.subtitle1}</div>
        </div>
        
   

        
        
        <div className="text"> {props.lang.castlePage.knowledgehub.content} </div>
            <a href={pdf} target="_blank" download >
        
            <div className='button-group'>
                    <div className="button"> {props.lang.castlePage.knowledgehub.button} </div>
                    <ArrowRight size={14}/>
            </div>
        </a>
        
    
    </div>

    {/* <div className="image" style={{ backgroundImage: `url(../assets/images/manifesto_hero_1.jpg)` }} ></div> */}
     <div className="image" style={{backgroundImage : `url(${pdfC})`}} ></div> 

     {/* <div className="hor-2-lines">
        <div className="line1"></div>
        <div className="line2"></div>
    </div> */}
    

</div>
  )
}

export default CastleHub