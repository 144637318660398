import React from 'react'
import "../styles/castle1.scss"
import bgimg from "../assets/images/slider/img4.JPG"

const Castle1 = (props) => {
  return (
   <>
    <div className='castle1-container'>
      <div className="pictures">
        <div className="picture p1"> </div>
        <div className="picture p2" > </div>
        {/* <div className="picture p2" style={{ backgroundImage: `url(https://images.unsplash.com/photo-1666833375493-2fb33e8ee28c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)` }}> 21 </div> */}
      </div>
    </div>
   </>
  )
}

export default Castle1