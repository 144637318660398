import React from 'react'

//COMPONENTS

import Breadcrumb from '../components/Breadcrumb';
import Manifesto1 from '../components/Manifesto1'
import Manifesto2 from '../components/Manifesto2';
import Manifesto3 from '../components/Manifesto3';
import Manifesto4 from '../components/Manifesto4';
import Manifesto5 from '../components/Manifesto5';
import Section6 from '../components/Section6';
import it from "../lang/it.json";

const ManifestoPage = () => {
  return (
    <>
      <Breadcrumb/>
      <Manifesto1 lang={it.manifestoPage}/>
      <div className="hor-2-lines">
        <div className="line1"></div>
        <div className="line2"></div>
      </div>

      <Manifesto2 lang={it.manifestoPage}/>

      <div className='sectionSeparator'> </div>

      <Manifesto3 lang={it}/>
      <Manifesto4 lang={it.manifestoPage}/>

      <div className="hor-2-lines display-none tablet">
        <div className="line1"></div>
        <div className="line2"></div>
      </div>

      {/* <Manifesto5 lang={it.manifestoPage}/> */}

       <div className="hor-2-lines desktop-only">
        <div className="line1"></div>
         {/* <div className="line2"></div>  */}
      </div> 
      {/* social media links */}
      <Section6 lang={it} />
    </>
    
  )
}

export default ManifestoPage