import React, { useState } from "react";
import "../styles/fakeCarousel.scss";
import { motion } from "framer-motion";
import ActivityCard from "./ActivityCard";
export default function FakeCarousel(props) {
  const [x, setX] = useState(0);
  function handleNext(index) {
    if (index % props.cards.length === 1) {
      // setX((((x + 1) % 4) + 4) % 4);
      setX((((x + 1) % 7) + 7) % 7);
      // ((n % m) + m) % m;
    }
  }
  return (
    <div className="fakeCarousel">
      {props.cards.map((card, index) => (
        <ActivityCard
          card={card}
          // index={(((index - x) % 4) + 4) % 4}
          index={(((index - x) % 7) + 7) % 7}
          total={props.cards.length}
          handleNext={handleNext.bind(this)}
        />
      ))}
    </div>
  );
}
