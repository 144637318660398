import React from "react";
import { CaretLeft } from "phosphor-react";
import { useNavigate, Link } from "react-router-dom";
import "../styles/breadcrumb.scss";

const Breadcrumb = (props) => {
  const navigate = useNavigate();
  return (
    <div className="breadcrumb-manifesto">
      <div className={`row1 ${props.castle ? "castle-bread" : ""}`}>
        <Link to="/">
          <CaretLeft size={24} className="arrow" />
        </Link>
        <Link to="/">
          {/* <CaretLeft size={24} weight="fill" color="#000000" />  */}
          <span className="gaterei"> GATE REI </span>
        </Link>
        <div className="vertical-bar"></div>
        <span className="manifesto">
          {" "}
          {props.castle ? "Castelnuovo Rangone" : "Manifesto"}{" "}
        </span>
      </div>

      <div className="row2"> </div>
    </div>
  );
};

export default Breadcrumb;
