import "./App.css";
import it from "./lang/it.json";

import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import Section5 from "./components/Section5";
import Section6 from "./components/Section6";
import Footer from "./components/Footer";
import KnowledgeHub from "./components/KnowledgeHub";
import GoToManifesto from "./components/GoToManifesto";

function App() {
  return (
    <div className="App">
      <Section1 lang={it} />
      <GoToManifesto lang={it} />
      <Section2 lang={it} />
      <Section3 lang={it} />
      <Section4 lang={it} />
      <KnowledgeHub lang={it} manifesto/>
      <Section5 lang={it} />
      <Section6 lang={it} />
     
    </div>
  );
}

export default App;
