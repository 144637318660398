import React, { useState, useEffect } from "react";
import { motion, AnimatePresence, useMotionValue } from "framer-motion";
import ManifestoActivityCard from "./ManifestoActivityCard";
import "../styles/manifestoCarousel.scss";
export default function Carousel(props) {
  const [isVisible, setIsVisible] = useState(0);
  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };
  const paginate = (newDirection) => {
    setIsVisible((isVisible + newDirection) % props.cards.length);
  };
  const variants = {
    enter: {
      x: 600,
      opacity: 0.6,
      transition: {
        duration: 0.4,
      },
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
      },
    },
    exit: {
      zIndex: 0,
      x: -600,
      opacity: 0.6,
      transition: {
        duration: 0.4,
      },
    },
  };
  const filledVariants = {
    visible: (isVisible) => ({
      width: `calc((100% / 7) *${isVisible + 1})`,
      // width: `calc((100% / 7) *${isVisible + 1})`,
    }),
  };

  function preventDefault(e) {
    e.preventDefault();
  }

  function disableScroll() {
    document.body.addEventListener("touchmove", preventDefault, {
      passive: false,
    });
  }
  function enableScroll() {
    document.body.removeEventListener("touchmove", preventDefault);
  }

  return (
    <div className="manifesto-carousel">
      <AnimatePresence>
        <motion.div
          className="carouselContent"
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            }

            preventDefault(e);
            enableScroll();
          }}
          key={props.cards[isVisible].title2}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          style={{ position: "absolute" }}
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 1.5 },
          }}
          onDragStart={(e) => {
            preventDefault(e);
            disableScroll();
          }}
        >
           {/* {props.cards[isVisible]} */}
          <ManifestoActivityCard card={props.cards[isVisible]} index={0} />
        </motion.div>
      </AnimatePresence>
      <div style={{ height: "20px", width: "100%" }} />
      <div className="carouselBar">
        <motion.div
          custom={isVisible}
          variants={filledVariants}
          animate="visible"
          className="carouselFilled"
        />
      </div>
    </div>
  );
}
