import React from "react";
import "../styles/section1.scss";
import logo from "../assets/icons/logo-white.png";
import s1 from "../assets/images/s1.jpg";
export default function Section1(props) {
  return (
    <div className="section s1" style={{ backgroundImage: `url(${s1})` }}>
      <div className="gradient g1" />
      <div className="gradient g2" />
      <div className="content">
        {<img src={logo} alt="gate-rei" />}
        {/* <div>
          <p className="titleP1">{props.lang.landingPage.section1.titleP1}</p>
          <p className="titleP2">{props.lang.landingPage.section1.titleP2}</p>
        </div> */}
      </div>
    </div>
  );
}
