import React, { useEffect } from "react";
import "../styles/activityCard.scss";
import { motion } from "framer-motion";
import { ArrowRight } from "phosphor-react";

import cres from "../assets/images/cres.jpg";
import inno from "../assets/images/inno.jpg";
import spa1 from "../assets/images/spa1.jpg";
import obie from "../assets/images/obie.jpg";
import spa2 from "../assets/images/spa2.jpg";
import impr from "../assets/images/impr.jpg";
import comp from "../assets/images/comp.jpg";
import hero1 from "../assets/images/manifesto_hero_1.jpg";
import hero2 from "../assets/images/manifesto_hero_2.jpg";

let pictures = {
  cres: cres,
  inno: inno,
  spa1: spa1,
  obie: obie,
  spa2: spa2,
  impr: impr,
  comp: comp,
};

let picturesCarouselMobile = {
  hero1: hero1,
  hero2: hero2,
}

let cardVariants = {
  visible: (i) => ({
    x:
      i === 2
        ? // i === 6
          `calc(((((100vw - 139px * 2) - 11 * 20px) / 12 * 10) + 9 * 20px + 139px + 20px)*${-1})`
        : `calc(((((100vw - 139px * 2) - 11 * 20px) / 12 * 10) + 9 * 20px + 139px + 20px)*${i})`,
    // x: (((1366 - 139 * 2 - 11 * 20) / 12) * 10 + 9 * 20 + 139 + 20) * (i % 3),
    cursor: i === 1 ? "pointer" : "",
    transition: {
      duration: 0.7,
    },
    // scale: i === 3 ? 0 : 1,
    opacity: i === 0 || i === 1 ? 1 : 0,
    // opacity: i === 0 || i === 1 ? 1 : 0,
    zIndex: i,
  }),
  hidden: { opacity: 0 },
};

let contentVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.6, duration: 0.3 },
  },
  closed: {
    opacity: 0,
    y: 72,
    transition: { delay: 0.6, duration: 0.7 },
  },
};



export default function ActivityCard(props) {
//   useEffect(() => {
//     console.log(props.cardImages);
//   }, [props.cardImages]);
  return (
    <>

    {props.card &&
    <motion.div
      custom={props.index}
      className="activityCard manifestoCard"
      variants={cardVariants}
      animate="visible"
      initial="visible"
      onClick={() => {
        props.handleNext(props.index);
      }}
      style={{ backgroundImage: `url(${pictures[props.card.shorthand]})` }}
    >
      <div className="activityCardBg" />
      <motion.div
        variants={contentVariants}
        animate={props.index === 0 ? "open" : "closed"}
        initial={props.index === 0 ? "open" : "closed"}
        className="content"
      >
        <div className="title">
          <p className="title1">{props.card.title1}</p>
          <p className="title2">{props.card.title2}</p>
          <div className="bar" />
        </div>
        <div className="text">
          <p className="text1">{props.card.text1}</p>
          <p className="text2">{props.card.text2}</p>
        </div>
        <a href={props.card.link} target="_blank" className="link">
          <p>{props.card.linkPlaceholder}</p>
          <ArrowRight size={18} />
        </a>
      </motion.div>
    </motion.div>
    }


    {props.cardImages && (
            <motion.div
            custom={props.index}
            className="activityCard manifestoCard"
            variants={cardVariants}
            animate="visible"
            initial="visible"
            onClick={() => {
              props.handleNext(props.index);
            }}
            style={{ backgroundImage: `url(${picturesCarouselMobile[props.cardImages.shorthand]})` }}
            
          >
            <div className="activityCardBg" />
            <motion.div
              variants={contentVariants}
              animate={props.index === 0 ? "open" : "closed"}
              initial={props.index === 0 ? "open" : "closed"}
              className="content"
            >
            </motion.div>
          </motion.div>
    )}
    </>
  );
}
