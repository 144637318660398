import React, { useState } from "react";
// import VideoCard from "./VideoCard";
import CastleSliderCard from "./CastleSliderCard";


const VideoCardsCarousel = (props) => {

  const [x, setX] = useState(0);

  function handleNext(index) {
    if (index % props.cards.length === 3) {
      setX(
        (((x + 1) % props.cards.length) + props.cards.length) %
          props.cards.length
      );
    }
  }
  

  return (
    <div className="carousel-container" style={{marginLeft: "var(--margin)"}}>
      <div
        className="fakeCarousel"
        style={{ width: `${props.cards.length * 300}px` }}
      >
        {props.cards.map((card, index) => (
          <CastleSliderCard
            cards={props.cards}
            key={index}
            card={card}
            index={
              (((index - x) % props.cards.length) + props.cards.length) %
              props.cards.length
            }
            total={props.cards.length}
            handleNext={handleNext.bind(this)}
          />
        ))}
      </div>
    </div>
  )
}

export default VideoCardsCarousel