import React from 'react'
import "../styles/gotomanifesto.scss";
//import arrow right from phosphor icons    
import { ArrowRight } from "phosphor-react";

import { Link } from "react-router-dom";

const GoToManifesto = (props) => {
  return (
    <>
    <div className='smallline'> </div>
    <div className='gotomanifesto-container'>
    <div className="content">
        <div className="orange-line"></div>
        
            <div className="title">{props.lang.landingPage.gotomanifesto.title}</div>
                    
        <div className="text"> {props.lang.landingPage.gotomanifesto.content} </div>

        <Link to={props.lang.landingPage.gotomanifesto.link}>
            <div className='button-group'>
                    <div className="button"> {props.lang.landingPage.gotomanifesto.button} </div>
                    <ArrowRight size={14}/>
            </div>
        </Link>
        
    
    </div>

    {/* <div className="image" style={{ backgroundImage: `url(../assets/images/manifesto_hero_1.jpg)` }} ></div> */}
     <div className="image"  ></div> 
    

</div>
</>
  )
}

export default GoToManifesto