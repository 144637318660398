import React from "react";
import "../styles/section5.scss";

export default function Section5(props) {
  return (
    <div className="section s5">
      <div className="content">
        <div className="title">
          <div className="bar" />
          <p>{props.lang.landingPage.section5.title}</p>
        </div>
        <div className="counselors">
          {props.lang.landingPage.section5.people.map((counselor, id) => (
            <>
              <div className="counselor">
                <img src={counselor.picture} alt={counselor.name} />
                <div className="title">
                  <div className="counselorJob">
                    <p className="counselorCompany">{counselor.company}</p>
                    <p className="counselorPosition">{counselor.position}</p>
                  </div>
                  <p className="counselorName">{counselor.name}</p>
                </div>
                <p className="bio">{counselor.bio}</p>
                <div className="links">
                  <div className="counselorEmail counselorContact">
                    <p className="platform">
                      {props.lang.landingPage.section5.email}
                    </p>
                    <p className="value">{counselor.email}</p>
                  </div>
                  <div className="counselorLinkedin counselorContact">
                    <p className="platform">
                      {props.lang.landingPage.section5.linkedIn}
                    </p>
                    <a href={counselor.linkedIn} target="_blank">
                      <p className="value">LinkedIn</p>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="counselorSeparator"
                style={{ display: id !== 4 ? "block" : "none" }}
              />
            </>
          ))}
        </div>
      </div>
      <div className="line l1" />
      <div className="line l2" />
      <div className="line l3" />
    </div>
  );
}
