import React from 'react'
import "../styles/manifesto4.scss";
// import hero1 from "../assets/images/manifesto_hero_1.jpg"

const Manifesto4 = (props) => {
  return (
    <div className='manifesto4-container'>
        <div className="content">
            <div className="blue-line"></div>
            <div className="title">{props.lang.section4.title}</div>
            <div className="text">
            {props.lang.section4.lines.map((line, index) => {   
                return (
                    <div className="line" key={index}> {line} </div>
                ) } )}      
        </div>
        </div>

        {/* <div className="image" style={{ backgroundImage: `url(../assets/images/manifesto_hero_1.jpg)` }} ></div> */}
         {/* <div className="image"  ></div>  */}
        
         <div className="hor-2-lines">
        <div className="line1"></div>
        <div className="line2"></div>
      </div>
    </div>
  )
}

export default Manifesto4