import React from "react";
import "../styles/footer.scss";
import logo from "../assets/icons/logo.svg";

export default function Footer(props) {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="legal">
            <div className="privacy">
              <p>© Gate REI all rights reserved 2024</p>
            </div>
            <div className="terms"></div>
            <div className="cookies"></div>
          </div>
          <div className="company">
            <p>GATE REAL ESTATE INNOVATION S.R.L. SOCIETA’ BENEFIT </p>
            <p>Viale Regina Margherita, 33</p>
            <p>09124 - Cagliari (CA)</p>
            <p>P.IVA 04005360922</p>
            <a href="mailto:gate.rei@pec.it" className="link">gate.rei@pec.it</a>
            <a href="https://gaterei.whistleflow.com/" className="link">Whistleblowing</a>
          </div>
          <div className="designed">
            <a href="https://matrixsoftwarehouse.com/" target="_blank">
              <p>Designed by Matrix Software House</p>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
