import React from "react";
import it from "../lang/it.json";

//COMPONENTS
import Breadcrumb from "../components/Breadcrumb";
import Castle1 from "../components/Castle1";
import Castle2 from "../components/Castle2";
import KnowledgeHub from "../components/KnowledgeHub";
import VideoCastle from "../components/VideoCastle";
import CastleSlider from "../components/CastleSlider";
import CastleCarousel from "../components/CastleCarousel";
import Section6 from "../components/Section6";

//STYLES
import "../styles/castlePage.scss";
import InterviewGrid from "../components/InterviewGrid";
import CastleCarouselsSection from "../components/CastleCarouselsSection";
import CastleHub from "../components/CastleHub";

const CastlePage = () => {
  return (
    <>
      <Breadcrumb castle />
      <Castle1 lang={it.castlePage.section1} />
      <Castle2 lang={it.castlePage} />
      {/* <KnowledgeHub lang={it} castle /> */}
      <CastleHub lang={it}/>
      {/* <div style={{ height: "16px" }}> </div> */}

      <VideoCastle />
      {/* <div style={{ height: "32px" }}> </div> */}

      <div style={{ position: "relative" }} className="carousels">
        {/* <CastleSlider cards={it.castlePage.slider.activities} />
        <CastleCarousel cards={it.castlePage.slider.activities} /> */}
        <CastleCarouselsSection />
      </div>

      {/* <div style={{ height: "32px" }}> </div> */}

      {/* <div className="lines">
        <div className="line l1" />
        <div className="line l2" />
      </div> */}

      <InterviewGrid videos={it.castlePage.interviewGrid} />
      <Section6 lang={it} />
    </>
  );
};

export default CastlePage;
